footer {
  background-color:$dark-grey;
  color:$complete-white;
}

.footer-copyright {
  background-color:#111;
  color:$complete-white;
  font-size: 12px;
}

footer a, footer a:hover {
  color: $complete-white;
}
footer .title {
  color: $light-grey;
  font-size: 12px;
}
footer ul li {
  margin-top: 15px;
  font-size: 14px;
}