@import 'colors';
@import 'forms';
@import 'cards';
@import 'footer';

body {
    background-color: $broken-white;
    font-family: 'Roboto Condensed', sans-serif;
}

a {
    color: $dominant-green;
    transition: 0.3s color ease-in;
}
a:hover {
    color: $dark-grey;
    text-decoration: none;
}


h1 {
    margin-top: 10vh;
    margin-bottom: 5vh;
}


.navbar {
    background-color: $complete-white;
    border-bottom: 1px solid $light-grey;
}
.navbar.navbar-transparent {
    background: transparent;
    border: 0;
}

.main-content {
    margin-top: 10vh;
    margin-bottom: 15vh;
}
.disclaimer {
    font-size: 13px;
    margin-top: 15px;
}


.payoff {
    font-size: 14px;
    font-weight: 300;
    font-variant: small-caps;
}
.jumbotron-fluid {
    background-image: url("https://www.map18.ch/en/public/maps/lipperswil/area/1-1_lipperswil.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: $complete-white;
    border-bottom: 2px solid $complete-white;
}

.profile-content {
    background-color: $complete-white;
    border-right: 1px solid $light-grey;
    padding-top: 15px;
}
.hcp {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: $dominant-green;
    color: $complete-white;
    line-height: 40px;
    font-weight: 700;
    font-size: 18px;
    border: 2px solid $complete-white;
    margin-top:-30px;
    margin-left: 50%;
    text-align: center;
}
.name {
    font-weight: 700;
    font-size: 18px;
}
.main-numbers {
    background-color: $near-black;
    color: $complete-white;
    padding: 15px 0;
    font-weight: 300;
    font-size: 14px;
}
.amount {
    font-weight: 700;
    font-size: 18px;
}

.main-menu ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.main-menu ul li{
    font-weight: 300;
    font-size: 14px;
    padding: 15px 0 15px 30px;
    width: 100%;
    cursor: pointer;
}
.main-menu ul li:hover, .main-menu ul li.active{
    background-color: $broken-white;
}
.main-menu ul li .active{
    position: absolute;
    left: 0;
    height: 25px;
    width: 5px;
    background-color: $dominant-green;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-caption {
    color: #333;
    text-align: left;
    bottom: 35%;
    right: 50%;
}
.carousel-indicators {
    justify-content: left;
}
.carousel-indicators li {
    background-color: #333;
}

.toggleMenu {
    cursor: pointer;
}

.hidden {
    display: none;
}

.jumbotron.qdisplay {
    background-image: url("/build/images/layout/QDisplay.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    color: $complete-white;
}

ol.breadcrumb {
    background-color: transparent;
    margin-top: -45px;
    padding-left: 0;

    a {
        color: $dominant-green;
        transition: 0.5s color;
    }

    a:hover {
        color: $dark-grey;
        text-decoration: none;
    }
}

.color-dark {
    color: $dark-grey;
}

.img-main-shadow {
    -webkit-box-shadow: 0 0 90px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 90px 1px rgba(0,0,0,0.1);
    box-shadow: 0 0 90px 1px rgba(0,0,0,0.1);
}

.main-btn {
    border-radius: 5px;
    border: 1px solid green;
    padding: 15px 30px;
    text-decoration: none;
    transition: 0.3s all ease-in;
}
.main-btn:hover {
    background-color: $dark-grey;
    color: $complete-white;
    border: 1px solid $dark-grey;
}

.jumbotron-gradient {
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 100%);
}