.card-group {
  border: 1px solid rgba(0,0,0,0);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  overflow: hidden;
  background-color: $complete-white;

  .card {
    border: 0;
    box-shadow: none;

    .card-footer {
      border: 0;
    }
  }
}

.card {
  border: 1px solid rgba(0,0,0,0);
  border-radius: 10px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  -moz-box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  overflow: hidden;

  .card-header {
    background-color: transparent;
    border: 0;

    h5 {
      margin: 0;
    }

  }

  .card-body {

    h5:after {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0; /* This will center the border. */
      width: 90px; /* Change this to whatever width you want. */
      padding-top: 5px; /* This creates some space between the element and the border. */
      border-bottom: 2px solid $dominant-green; /* This creates the border. Replace black with whatever color you want. */
      transition: 0.5s width ease-in;
    }

  }

  .card-footer {
    background-color: $complete-white;

    a, a:hover, a:active {
      color: $dark-grey;
      text-decoration: none;
    }
  }
}

.card:hover {
  .card-body {

    h5:after {
      content: ""; /* This is necessary for the pseudo element to work. */
      display: block; /* This will put the pseudo element on its own line. */
      margin: 0; /* This will center the border. */
      width: 100%; /* Change this to whatever width you want. */
      padding-top: 5px; /* This creates some space between the element and the border. */
      border-bottom: 2px solid $dominant-green; /* This creates the border. Replace black with whatever color you want. */
    }

  }
}