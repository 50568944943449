input.signup {
  margin-top:15px;
  width: 100%;
  padding:15px 30px;
  border: 1px solid $light-grey;
}

input[type=email], input[type=password] {
  width:100%;
  padding:15px 30px;
  border: 1px solid $light-grey;
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  opacity: 1;
}

label {
  margin-top: 15px;
}

button.btn {
  margin-top:15px;
  background-color:$dominant-green;
  color:$complete-white;
  padding:15px 45px;
  width: 100%;
  margin-bottom: 30px;
}
button.btn-no-margin {
  margin-top: 0;
}

.alert ul {
  margin: 0;
}

